import { Client } from '@twilio/conversations'
import { useEffect, useState } from 'react'

import { useMutateData } from '@unreserved-frontend-v2/api/base-hooks/use-mutate-data'
import {
  GenerateRealTimeNotificationTokenMutation,
  GenerateRealTimeNotificationTokenMutationVariables,
  useGenerateRealTimeNotificationTokenMutation,
} from '@unreserved-frontend-v2/api/generated/graphql/generate-real-time-notification-token.graphql'
import { UseCreateModifyMutationQueryType } from '@unreserved-frontend-v2/api/hooks/use-create-modify-mutation'

/**
 * Hook that instantiates twilio conversations client sdk using a token generated from our backend
 *
 * The twilio SDK event subscriptions are the mechanism that provide our clients realtime notifications from our back-end,
 * primarily for our 'threads' feature. The structure is so that back-end handles most of the twilio integration
 * while clients only use the sdk for event notifications. Clients only read and write data with mutations and queries from our back-end
 * api. Twilio client sdk is not to be used directly to mutate or read conversations data.
 *
 * docs: https://www.twilio.com/docs/conversations/sdk-overview
 */
export const useTwilio = (userReady: boolean) => {
  const [twilioClient, setTwilioClient] = useState<Client>()
  const [fetchingStarted, setFetchingStarted] = useState(false)

  const { mutateAsync: generateTokenAsync } = useMutateData<
    undefined,
    GenerateRealTimeNotificationTokenMutation,
    GenerateRealTimeNotificationTokenMutationVariables
  >(useGenerateRealTimeNotificationTokenMutation as UseCreateModifyMutationQueryType)

  useEffect(() => {
    if (userReady && !fetchingStarted) {
      setFetchingStarted(true)

      generateTokenAsync(undefined).then((data) => {
        if (!data?.generateRealTimeNotificationToken) {
          return
        }

        const client = new Client(data.generateRealTimeNotificationToken)
        const handleTokenUpdate = () =>
          generateTokenAsync(undefined)
            .then((token) => {
              client.updateToken(token.generateRealTimeNotificationToken as string)
            })
            .catch(() => {
              console.error('API error generating token')
            })

        client.on('connectionStateChanged', (state) => {
          switch (state) {
            case 'connected':
              setTwilioClient(client)
              return
            // if there is an error or we are disconnected in the SDK for some reason, let's try to reconnect
            case 'error':
            case 'disconnected':
              handleTokenUpdate()
              return
            case 'connecting':
            case 'denied':
            case 'disconnecting':
            case 'unknown':
              return
          }
        })

        // eagerly refresh token before it expires for a smoother experience
        client.on('tokenAboutToExpire', () => {
          handleTokenUpdate()
        })

        // token can expire without a retry if your computer goes to sleep for more than 1 hour
        client.on('tokenExpired', () => {
          handleTokenUpdate()
        })
      })
    }
  }, [userReady, generateTokenAsync, fetchingStarted])

  return {
    twilioClient,
  }
}
